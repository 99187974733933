<template>
  <div class="menuContent">
    <div class="listMenu flex-row">
      <div class="leftTitle">一级菜单:</div>
      <div class="rightContent">
        <div
          v-for="(item,index) in menus"
          :key="index"
          @click="enterHandler(index, 1);levelMenuData(item.value, 0, index)"
        >
          <span :class="{active: selectIndex[0] * 1===index}">{{item.label}}</span>
        </div>
      </div>
    </div>
    <div class="listMenu flex-row" v-show="secondMenus.length > 0">
      <div class="leftTitle">二级菜单:</div>
      <div class="rightContent">
        <div
          v-for="(item,index) in secondMenus"
          :key="index"
          @click="enterHandler(index, 2);levelMenuData(item.value, 1, index)"
        >
          <span :class="{active: selectIndex[1] * 1===index}">{{item.label}}</span>
        </div>
      </div>
    </div>
    <div class="listMenu flex-row" v-show="thirdMenus.length > 0">
      <div class="leftTitle">三级菜单:</div>
      <div class="rightContent">
        <div
          class
          v-for="(item,index) in thirdMenus"
          :key="index"
          @click="levelMenuData(item.value, 2, index);levelMenuData(item.value, 2, index)"
        >
          <span :class="activeClass == index ? 'active' : ''" :key="index" @click="getItem(index)">{{item.label}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menus: [],
      secondMenus: [],
      thirdMenus: [],
      selectIndex: [-1, -1, -1],
      activeClass: -1,
    }
  },
  mounted() {
    this.queryMenu()
  },
  methods: {
    getItem(index){
this.activeClass = index;
},
    queryMenu() {
      this.$http.get('/productCate/getTree').then(res => {
        const { data } = res.data
        this.menus = data
      })
    },
    enterHandler(index, flag) {
      if (flag === 1) {
        this.secondMenus = (this.menus[index] && this.menus[index]['children']) || []
        this.thirdMenus = []
      } else {
        this.thirdMenus = (this.secondMenus[index] && this.secondMenus[index]['children']) || []
      }
    },
    levelMenuData(item, index, value) {
      this.selectIndex[index] = value
      this.$emit('change', item)
    }
  }
}
</script>
<style lang="scss" scoped>
.menuContent {
  width: 1200px;
  background: #fff !important;
  padding:0 20px;
  margin:0 auto;
  .listMenu {
    padding: 6px 0;
    height: auto;
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    font-size: 16px;
    &:not(:last-child) {
      border-bottom: 1px dashed #ddd;
    }
    .leftTitle {
      padding-top: 2px;
      width: 77px;
      color: #333;
      font-size: 14px;
    }
    .rightContent {
      flex: 1;
      height: auto;
      line-height: 25px;
      font-size: 14px;
      div {
        display: inline-block;
      }
      span {
        flex: 1;
        padding: 0 15px;
        text-align: center;
        &:hover {
          color: #d82229;
          cursor: pointer;
        }
        &.active {
          color: #d82229;
          cursor: pointer;
        }
      }
    }
  }
}
</style>


